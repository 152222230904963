<template>
    <div class="sign-up">
        <div class="sign-up-form">
            <el-form
                    ref="ruleForm"
                    :model="ruleForm"
                    status-icon
                    :rules="rules"
                    label-width="70px"
                    class="demo-ruleForm"
            >
                <el-form-item label="用户名" prop="userName">
                    <el-input
                            v-model="ruleForm.userName"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="昵称" prop="nickname">
                    <el-input
                            v-model="ruleForm.nickname"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="pass">
                    <el-input
                            v-model="ruleForm.pass"
                            type="password"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="密码确认" prop="checkPass">
                    <el-input
                            v-model="ruleForm.checkPass"
                            type="password"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')"
                    >注册
                    </el-button
                    >
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import { ElMessage } from 'element-plus';
    import {USERS} from "@/constants/api";

    export default defineComponent ({
        name: 'SignUp',
        data() {
            const validateUserName = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入用户名'))
                }
                callback()
            }
            const validateNickname = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入昵称'))
                }
                callback()
            }
            const validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入密码'))
                } else {
                    if (this.ruleForm.checkPass !== '') {
                        this.$refs.ruleForm.validateField('checkPass')
                    }
                    callback()
                }
            }
            const validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'))
                } else if (value !== this.ruleForm.pass) {
                    callback(new Error("密码不一致！"))
                } else {
                    callback()
                }
            }
            return {
                ruleForm: {
                    userName: '',
                    pass: '',
                    checkPass: '',
                    nickname: ''
                },
                rules: {
                    userName: [{ validator: validateUserName, trigger: 'blur' }],
                    pass: [{ validator: validatePass, trigger: 'blur' }],
                    checkPass: [{ validator: validatePass2, trigger: 'blur' }],
                    nickname: [{ validator: validateNickname, trigger: 'blur' }]
                },
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        const router = this.$router;
                        this.axios.post(USERS, {
                            username: this.ruleForm.userName,
                            password: this.ruleForm.pass,
                            nickname: this.ruleForm.nickname
                        }).then(function (response) {
                            ElMessage({
                                showClose: true,
                                message: '注册成功',
                                type: 'success',
                            });
                            setTimeout(function () {
                                router.push('/');
                            }, 2000);
                            // console.log(response);
                        }).catch(function (error) {
                            const data = error.response.data;
                            if (data != null) {
                                ElMessage({
                                    showClose: true,
                                    message: error.response.data.error,
                                    type: 'error',
                                });
                            } else {
                                ElMessage({
                                    showClose: true,
                                    message: '注册失败',
                                    type: 'error',
                                });
                            }
                            // console.log(error);
                        });
                    } else {
                        console.log('error submit!!')
                        return false
                    }
                })
            },
            resetForm(formName) {
                this.$refs[formName].resetFields()
            },
        },
    });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    .sign-up {
        padding-top: 24px;
        margin: 42px auto;
    }
    .sign-up-form {
        margin: 42px auto;
        padding: 16px;
        max-width: 640px;
    }
</style>